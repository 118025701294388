import React from "react";
import loadable from "@loadable/component";
//antd에서 아이콘 가져오기
import {
  UserOutlined,
  SettingOutlined,
  DollarCircleOutlined,
  ProfileOutlined,
  ReadOutlined,
  ShopOutlined,
  PhoneOutlined,
  BellOutlined,
  TeamOutlined
} from "@ant-design/icons";
//회원 전용 루트
import { PUBLIC_ROUTE } from "./routes.constants";
import { Redirect } from "react-router";

//로그인 없이 이용가능 페이지
export const publicRoutes = [
  //첫시작 로그인으로 설정 => ('/' 해당 url 사용 안함)
  {
    exact: true,
    path: PUBLIC_ROUTE.ROOT,
    component: () => {
      if (localStorage.getItem("token")) {
        return <Redirect to="/diet/category" />
      } else {
        return <Redirect to="/login" />
      }
    },
  },
  {
    exact: true,
    path: PUBLIC_ROUTE.LOGIN,
    component: loadable(() => import("../pages/auth/Login")),
  },
];

//로그인 시에만 이용가능 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/diet",
    sidebar: {
      icon: <ProfileOutlined />,
      label: "식단관리",
    },
    children: [
      {
        exact: true,
        path: "/category",
        sidebar: {
          label: "식단 카테고리 관리",
        },
        component: loadable(() => import("../pages/diet/category/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/detail/:id",
        component: loadable(() => import("../pages/diet/category/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/register",
        component: loadable(() => import("../pages/diet/category/Register")),
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "식단 리스트",
        },
        component: loadable(() => import("../pages/diet/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/diet/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/diet/Register")),
      },
    ],
  },
  {
    exact: true,
    path: "/recipe",
    sidebar: {
      icon: <ReadOutlined />,
      label: "레시피 관리",
    },
    children: [
      {
        exact: true,
        path: "/category",
        sidebar: {
          label: "레시피 카테고리 관리",
        },
        component: loadable(() => import("../pages/recipe/category/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/detail/:id",
        component: loadable(() => import("../pages/recipe/category/Detail")),
      },
      {
        exact: true,
        visible: false,
        path: "/category/register",
        component: loadable(() => import("../pages/recipe/category/Register")),
      },
      {
        exact: true,
        path: "",
        sidebar: {
          label: "일반/기업 레시피 리스트",
        },
        component: loadable(() => import("../pages/recipe/company/List")),
      },
      {
        exact: true,
        visible: false,
        path: "/register",
        component: loadable(() => import("../pages/recipe/company/Register")),
      },
      {
        exact: true,
        visible: false,
        path: "/detail/:id",
        component: loadable(() => import("../pages/recipe/company/Modify")),
      },
    ],
  },
];
